import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import '../index.css';
import * as serviceWorker from '../serviceWorker';
import { RootFrame } from '../shared/presentation/RootFrame';
import { Home } from './Home';
import { Movie } from './movie/Movie';
import { MovieAuto } from './movie/auto/MovieAuto';
import { ImageScrapView } from './image/ImageScrapView';
import { ImageDetailView } from './image/ImageDetailView';
import { GameView } from './game/GameView';

// test
ReactDOM.render(
  <RootFrame>
    <Switch>
      <Route exact path={'/movie/auto'} component={MovieAuto}/>
      <Route exact path={'/movie'} component={Movie}/>
      <Route exact path={'/image'} component={ImageScrapView}/>
      <Route exact path={'/image/detail'} component={ImageDetailView}/>
      <Route exact path={'/game'} component={GameView}/>
      <Route component={Home}/>
    </Switch>
  </RootFrame>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

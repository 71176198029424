import React, { useCallback } from 'react';
import { css, StyleSheet } from 'aphrodite';

export interface IMovieTime {
  theaterName: string;
  movieName: string;
  playYMD: string;
  playStart: string;
  playEnd: string;

  theaterCode: string;
  movieCode: string;
  screenCode: string;
  remainSeatCount: string;
  seatCapacity: string;
  playTimeCode: string;
  playNum: string;
}

export interface IProps {
  onClick: () => void,
}

export const MovieTime: React.FC<IMovieTime & IProps> = (item) => {
  const mapYDMToFormat = useCallback((value: string) => value.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3'), []);
  const mapTimeToFormat = useCallback((value: string) => value.replace(/(\d{2})(\d{2})/, '$1:$2'), []);

  return <div className={css(styles.movieTime)}>
    <div className={css(styles.movieTimeTheater)}>
      {item.theaterName}
    </div>
    <div className={css(styles.movieTimeTitle)}>
      {item.movieName}&nbsp;
    </div>
    <div className={css(styles.movieTimeYMD)}>
      {mapYDMToFormat(item.playYMD)}
    </div>
    <div className={css(styles.movieTimeHHMM)}>
      시간: {mapTimeToFormat(item.playStart)} ~ {mapTimeToFormat(item.playEnd)}
    </div>
    <div className={css(styles.space)}/>
    <button
      type={'button'}
      className={css(styles.popupButton)}
      value={'예약'}
      onClick={item.onClick}
    >
      예약
    </button>
  </div>;
};

const styles = StyleSheet.create({
  movieTime: {
    padding: 10,

    width: 140,
    height: 120,

    display: 'flex',
    flexDirection: 'column',

    borderRadius: 10,
    backgroundColor: '#E2E3E4',

    fontFamily: 'Spoqa',
  },
  movieTimeTheater: {
    fontSize: 12,
    marginBottom: 5,
  },
  movieTimeTitle: {
    fontWeight: 700,
    fontSize: 14,
  },
  movieTimeYMD: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 12,
  },
  movieTimeHHMM: {
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 5,
  },
  space: {
    flexGrow: 1,
  },
  popupButton: {
    alignSelf: 'flex-end',
    width: 80,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#006AFF',

    color: '#FFF',
    fontSize: 12,
    fontWeight: 700,

    cursor: 'pointer',
  },
});

import React, { useCallback, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';
import qs from 'qs';

import { Body } from '../../shared/presentation/Body';
import { onKeyEnter } from '../../shared/domain/KeyBoardKey';
import { isLoadingState } from '../../shared/presentation/LoadingFrame';
import { PopupFrame } from '../../shared/presentation/PopupFrame';
import { IMovieTime, MovieTime } from './MovieTime';

export const Movie: React.FC = () => {
  const [movieTimes, setMovieTimes] = useState<IMovieTime[]>([]);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const inputRef = useRef({ title: '', type: '', regions: '', seatNum: 1, });
  const [selectedMovie, setSelectedMovie] = useState<IMovieTime>();

  const searchMovies = useCallback(async () => {
    setIsLoading(true);
    const { title, type, regions } = inputRef.current;
    const query = qs.stringify({
      name: title,
      movieType: type,
      theaters: regions.split(','),
    });
    try {
      const { data: movieTimes } = await axios.get(
        'https://api.jakestory.me/movie/times?' + query,
      ) as { data: IMovieTime[] };
      setMovieTimes(movieTimes);
    } catch (e) {
      alert(`영화 정보를 가져오는데 실패하였습니다. \n에러 정보: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [inputRef, setIsLoading])

  const reserveMovie = useCallback(async (seatNum: number) => {
    const time = selectedMovie;
    setIsLoading(true);
    try {
      const { data: { reservationNo, seats } } = await axios.post(
        'https://api.jakestory.me/movie/reserve',
        { time, seatNum },
      );
      alert(`예약되었습니다. \n예약번호: ${reservationNo} \n예약좌석: ${seats}`);
    } catch (e) {
      alert(`예약이 실패하였습니다. \n에러 정보: ${e.message}`);
    } finally {
      setSelectedMovie(undefined);
      setIsLoading(false);
    }
  }, [selectedMovie, setIsLoading])

  return <Body>
    <div className={css(styles.inputBox)}>
      <div className={css(styles.title_label)}>1. 영화 제목</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.title = e.target.value}
        onKeyDown={(e) => onKeyEnter(e, () => searchMovies())}
      />
      <div className={css(styles.title_label)}>2. 영화 타입 (All or IMAX)</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.type = e.target.value}
        onKeyDown={(e) => onKeyEnter(e, () => searchMovies())}
      />
      <div className={css(styles.title_label)}>3. 영화관 (강남, 압구정, 왕십리, 용산)</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.regions = e.target.value}
        onKeyDown={(e) => onKeyEnter(e, () => searchMovies())}
      />
      <button
        type={'button'}
        className={css(styles.inputButton)}
        onClick={searchMovies}
      >
        검색
      </button>
    </div>
    <div className={css(styles.output)}>
      <div className={css(styles.times)}>
        {movieTimes.map((item, index) => (
          <MovieTime
            {...item}
            onClick={() => setSelectedMovie(item)}
            key={`time-${index}`}
          />
        ))}
      </div>
    </div>
    {selectedMovie && (
      <PopupFrame>
        <div className={css(styles.popupBox)}>
          <div className={css(styles.popupTitle)}>
            예약 인원수
          </div>
          <input
            className={css(styles.movie_input, styles.center)}
            type={'number'}
            onChange={e => inputRef.current.seatNum = Number(e.target.value)}
            defaultValue={inputRef.current.seatNum}
          />
          <button
            type={'button'}
            className={css(styles.reserveButton, styles.cancelButton)}
            value={'예약'}
            onClick={() => setSelectedMovie(undefined)}
          >
            취소
          </button>
          <button
            type={'button'}
            className={css(styles.reserveButton)}
            value={'예약'}
            onClick={() => reserveMovie(inputRef.current.seatNum)}
          >
            예약
          </button>
        </div>
      </PopupFrame>
    )}
  </Body>;
}

const styles = StyleSheet.create({
  inputBox: {
    flexShrink: 1,
    width: 300,
    height: 'calc(100vh - 100px)',
    borderRadius: 20,
    borderColor: '#000',
    borderWidth: 1,
    // backgroundColor: '#000'
    marginRight: 20,

    padding: '30px 20px',
    position: 'relative',
  },
  output: {
    flexShrink: 0.5,
    width: 700,
    height: 'calc(100vh - 100px)',

    padding: '20px 20px',
    userSelect: 'auto',
  },
  title_label: {
    fontFamily: 'Spoqa',
    fontWeight: 700,

    marginBottom: 8,
  },
  movie_input: {
    width: '100%',
    height: 40,

    borderRadius: 10,
    borderColor: '#000',
    borderWidth: 1,
    padding: 18,

    marginBottom: 16,
  },
  times: {
    paddingBottom: 20,
    width: '100%',
    minHeight: 120,
    borderBottom: '1px solid #E2E4E6',

    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
  },
  inputButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 200,
    height: 50,

    borderRadius: 10,
    backgroundColor: '#006AFF',

    color: '#FFF',
    fontSize: 16,
    fontWeight: 700,

    cursor: 'pointer',
  },
  popupBox: {
    position: 'relative',
    width: 300,
    height: 200,
    borderRadius: 50,
    backgroundColor: '#FFF',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: 30,
  },
  popupTitle: {
    marginBottom: 30,

    fontFamily: 'Spoqa',
    fontWeight: 700,
    fontSize: 24,
  },
  center: {
    textAlign: 'center',
  },
  reserveButton: {
    position: 'absolute',
    bottom: 10,
    right: 30,

    width: 80,
    height: 30,
    borderRadius: 10,
    backgroundColor: '#006AFF',

    color: '#FFF',
    fontSize: 12,
    fontWeight: 700,

    cursor: 'pointer',
  },
  cancelButton: {
    bottom: 10,
    right: 120,

    backgroundColor: '#C2C4C6',
  }
})

import React, { useCallback, useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';

import { onKeyEnter } from '../../shared/domain/KeyBoardKey';
import { isLoadingState } from '../../shared/presentation/LoadingFrame';
import { imageUrlState } from './ImageDetailView';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

export const ImageScrapView: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [tag, setTag] = useState('');
  const [count, setCount] = useState('20');

  const location = useLocation();
  const query = qs.parse(location.search);
  const { tag: sTag, count: sCount} = query;

  const scrapImages = useCallback(async () => {
    const { tag, count } = query;
    console.log(query);
    if (tag == null || count == null) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axios.get(`https://api.jakestory.me/image-scraper/korean-tag?tag=${sTag}&count=${sCount}`);
      setImages(data);
    } finally {
      setIsLoading(false);
    }
  }, [query]);

  useEffect(() => {scrapImages()}, [sTag, sCount])

  const refreshWithSearch = () => {
    history.replace(`image?tag=${tag}&count=${count}`);
  }

  return <>
    <input
      className={css(styles.input)}
      onChange={e => setTag(e.target.value)}
      onKeyDown={(e) => onKeyEnter(e, () => refreshWithSearch())}
      defaultValue={''}
    />
    <input
      type={'number'}
      className={css(styles.input)}
      onChange={e => setCount(e.target.value)}
      value={count}
      onKeyDown={(e) => onKeyEnter(e, () => refreshWithSearch())}
    />
    <div className={css(styles.imagesBox)}>
      {images.map((item: any, index) => (
        <a
          key={'ima-' + index}
          className={css(styles.imageWrapper)}
          href={`image/detail?url=${item.code}`}
          // onClick={() => {
          //   window.open(`image/detail?url=${item.link}`);
          // }}
        >
          <img src={item.thumb} alt={'thumb'} className={css(styles.image_thumb)}/>
          <p className={css(styles.image_title)}>{item.filteredTitle}</p>
        </a>
      ))}
    </div>
  </>;
};

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 40,

    borderRadius: 10,
    borderColor: '#000',
    borderWidth: 1,
    padding: 18,

    marginBottom: 16,
  },
  imagesBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'space-between',
  },
  imageWrapper: {
    width: 350,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image_thumb: {
    width: 300,
    height: 400,
    marginBottom: 5,
  },
  image_title: {
    fontFamily: 'Spoqa',
    fontSize: 18,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    wordBreak: 'keep-all',
  }
});

import { css, StyleSheet } from 'aphrodite';
import React from 'react';

export const PopupFrame: React.FC = ({children}) => {
  return <div className={css(styles.wrapper)}>
    {children}
  </div>
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.2)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

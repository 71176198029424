import React, { useCallback, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import axios from 'axios';

import './assets/jake.css';
import { KeyboardKey } from '../shared/domain/KeyBoardKey';
import { useSetRecoilState } from 'recoil';
import { isLoadingState } from '../shared/presentation/LoadingFrame';

interface VersicleOrHymn {
  title: string,
  contents: {
    isBold: boolean,
    content: string,
  }[];
}

export const Home: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const [versicle, setVersicle] = useState<VersicleOrHymn>({ title: '', contents: [] });
  const [links, setLinks] = useState([]);

  const showVersicle = useCallback(async (value: string) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        'https://api.jakestory.me/broadcast/versicle?index=' + value,
      );
      setVersicle(data);
    } finally {
      setIsLoading(false);
    }
  }, [setVersicle, setIsLoading]);

  const showHymn = useCallback(async (value: string) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        'https://api.jakestory.me/broadcast/hymn?index=' + value,
      );
      data.contents = data.contents.map((v: string) => ({ content: v, isBold: false }))
      setVersicle(data);
    } finally {
      setIsLoading(false);
    }
  }, [setVersicle, setIsLoading]);

  const showBible = useCallback(async (value: string) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        'https://api.jakestory.me/broadcast/bible?search=' + value,
      );
      setVersicle(data);
    } finally {
      setIsLoading(false);
    }
  }, [setVersicle, setIsLoading]);

  const showLyrics = useCallback(async (value: string) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        'https://api.jakestory.me/broadcast/lyrics?search=' + value,
      );
      setVersicle(data);
    } finally {
      setIsLoading(false);
    }
  }, [setVersicle, setIsLoading]);

  const copy = useCallback((index: number) => {
    const element = document.getElementById('copy') as HTMLInputElement;
    const { content: copyData } = versicle.contents[index];
    element!.value = copyData.replace(/ ^/, '');
    element!.select();
    document.execCommand('copy');
  }, [versicle]);

  const showNotice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('https://api.jakestory.me/broadcast/notice-link');
      setLinks(data);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading])
  return (
    <article className={css(styles.body)}>
      <div className={css(styles.inputBox)}>
        <div className={css(styles.res_label)}>1. 교독문 입력</div>
        <input
          className={css(styles.res_input)}
          onKeyDown={async (e) => {
            e.persist();
            e.stopPropagation();
            const inputElement = e.target as HTMLInputElement;
            switch (e.keyCode) {
              case KeyboardKey.Enter: // Enter
                await showVersicle(inputElement.value);
                return;
              default:
                return;
            }
          }}
        />
        <div className={css(styles.res_label)}>2. 찬송가 입력</div>
        <input
          className={css(styles.res_input)}
          onKeyDown={async (e) => {
            e.persist();
            e.stopPropagation();
            const inputElement = e.target as HTMLInputElement;
            switch (e.keyCode) {
              case KeyboardKey.Enter: // Enter
                await showHymn(inputElement.value);
                return;
              default:
                return;
            }
          }}
        />
        <div className={css(styles.res_label)}>3. 성경 입력</div>
        <input
          className={css(styles.res_input)}
          onKeyDown={async (e) => {
            e.persist();
            e.stopPropagation();
            const inputElement = e.target as HTMLInputElement;
            switch (e.keyCode) {
              case KeyboardKey.Enter: // Enter
                await showBible(inputElement.value);
                return;
              default:
                return;
            }
          }}
        />
        <div className={css(styles.res_label)}>4. CCM 입력</div>
        <input
          className={css(styles.res_input)}
          onKeyDown={async (e) => {
            e.persist();
            e.stopPropagation();
            const inputElement = e.target as HTMLInputElement;
            switch (e.keyCode) {
              case KeyboardKey.Enter: // Enter
                await showLyrics(inputElement.value);
                return;
              default:
                return;
            }
          }}
        />
        <input type={'button'} value={'주보 로드'} onClick={() => showNotice()}/>
        {links.map((link, index) => (
          <div
            onClick={() => window.open(link)}
            className={css(styles.link)}
            key={'link-' + index}
          >
            주보 링크 {index+1}
          </div>
        ))}
        <input id={'copy'} style={{opacity: 0}}/>
      </div>
      <div className={css(styles.output)}>
        <h3 className={css(styles.title)}>{versicle.title}</h3>
        {versicle.contents.map((item, index) => (
          <p
            key={'content-' + index}
            className={css(styles.content, item.isBold && styles.bold)}
            id={'content-' + index}
          >
            {item.content}
            <input
              type={'button'}
              value={'복사'}
              className={css(styles.button)}
              onClick={() => copy(index)}
            />
          </p>
        ))}
      </div>
    </article>
  );
};

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    paddingTop: 50,
  },
  inputBox: {
    flexShrink: 1,
    width: 300,
    height: 500,
    borderRadius: 20,
    borderColor: '#000',
    borderWidth: 1,
    // backgroundColor: '#000'
    marginRight: 20,

    padding: '30px 20px',
  },
  res_label: {
    fontFamily: 'Spoqa',
    fontWeight: 700,

    marginBottom: 8,
  },
  res_input: {
    width: '100%',
    height: 40,

    borderRadius: 10,
    borderColor: '#000',
    borderWidth: 1,
    padding: 18,

    marginBottom: 16,
  },
  output: {
    flexShrink: 0.5,
    width: 700,
    height: 500,

    padding: '20px 20px',
    userSelect: 'auto',
  },
  title: {
    marginBottom: 12,
  },
  content: {
    width: 'max-content',
    maxWidth: '95%',
    position: 'relative',
    marginBottom: 12,
    whiteSpace: 'pre-wrap',

    ':nth-child(1n) > span:first-child': {
      display: 'none',
    },
    ':last-child > span:first-child': {
      display: 'block',
    }
  },
  bold: {
    fontWeight: 700,
  },
  button: {
    position: 'absolute',
    right: -8,
    top: '50%',
    transform: 'translate(100%, -50%)',

    width: 40,
    height: 24,
    borderRadius: 8,

    backgroundColor: '#006AFF',
    color: '#FFF',
    cursor: 'pointer',
    transition: 'all .3s',
    ':hover': {
      backgroundColor: '#004ADF',
      ':active': {
        backgroundColor: '#228AFF',
      },
    },
  },
  link: {
    marginTop: 8,
    fontSize: 12,
    color: '#006AFF',
    textDecoration: 'underline',
  }
});

import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { RecoilRoot } from 'recoil';

import { LoadingFrame } from './LoadingFrame';
import { InversifyProvider } from './InversifyProvider';
import { container } from '../inversify.config';

interface Postcode {
  new(input: { oncomplete: (data: any) => void }): Postcode;
  open: () => void;
}

// interface SocketIO {
//   new(url: string): any;
// }

declare global {
  interface Window {
    cloudinary: {
      openUploadWidget: (
        options: { cloudName: string, uploadPreset: string },
        handler: (error: any, result: any) => void,
      ) => void,
      createUploadWidget: (
        options: { cloudName: string, uploadPreset: string },
        handler: (error: any, result: any) => void,
      ) => any,
    };
    cloudinaryWidget: {
      open: () => void,
    };
    daum: {
      Postcode: Postcode;
    };
    io: (url: string) => any;
  }
}

interface RootProps {
  rootStore?: any,
  children?: any,
}

export const RootFrame = ({ children }: RootProps) => (
  <InversifyProvider container={container}>
    <BrowserRouter>
      <RecoilRoot>
        {/*<ReactHelmet*/}
        {/*  project={project}*/}
        {/*/>*/}
        <LoadingFrame/>
        {children}
      </RecoilRoot>
    </BrowserRouter>
  </InversifyProvider>
);

import Phaser from 'phaser';
import React, { useEffect, useState } from 'react';

import logoImage from '../assets/background.png';

interface GameComponent {
  someState: any;
}

export const GameView: React.FC<GameComponent> = ({ someState }) => {
  const [isReady, setReady] = useState(false);

  // const dataService = (changedState: any) => {
  //   // I'm not sure how to use stores, but you'll know better what to do here.
  //   store.dispatch(
  //     {
  //       ...someState,
  //       ...changedState,
  //     },
  //     { type: ACTION_TYPE }
  //   )
  // }

  useEffect(() => {
    const config = {
      callbacks: {
        preBoot: (game: any) => {
          // A good way to get data state into the game.
          game.registry.merge(someState);
          // This is a good way to catch when that data changes.
          game.registry.events.on('changedata', (par: any, key: any, val: any, prevVal: any) => {
            // Simply call whatever functions you want outside.
            // dataService({ [key]: val })
          });
        },
      },
      type: Phaser.AUTO,
      parent: 'phaser-example',
      width: 800,
      height: 600,
      scene: [ExampleScene],
    };
    let game = new Phaser.Game(config);
    // Triggered when game is fully READY.
    game.events.on('READY', setReady);
    // If you don't do this, you get duplicates of the canvas piling up.
    return () => {
      setReady(false);
      game.destroy(true);
    };
  }, []);

  return <div id="phaser-example" className={isReady ? "visible" : "invisible"} />;
};

export default class ExampleScene extends Phaser.Scene {
  preload() {
    this.load.image('logo', logoImage);
  }

  create() {
    // You made this!
    const text = this.add.text(250, 250, 'Phaser');
    text.setInteractive({ useHandCursor: true });
    this.add.image(800, 300, 'logo');
    /** @tutorial I made this! */
      // Get all that lovely dataState into your scene,
    let { clickCount } = this.registry.getAll();
    text.on('pointerup', () => {
      // This will trigger the "changedata" event handled by the component.
      this.registry.merge({ clickCount: clickCount++ });
    });
    // This will trigger the scene as now being ready.
    this.game.events.emit('READY', true);
  }
}

import React from 'react';
import { css, StyleSheet } from 'aphrodite';

export const Body: React.FC = ({ children }) => {
  return <article className={css(styles.body)}>
    {children}
  </article>;
};

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    paddingTop: 50,
  },
});

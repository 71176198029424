import { css, StyleSheet } from 'aphrodite';
import React, { useCallback, useRef } from 'react';
import { selector, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import axios from 'axios';

import { Body } from '../../../shared/presentation/Body';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';

const MOVIES_STATE = selector<any[]>({
  key: 'MOVIES_STATE',
  get: async ({}) => {
    const { data } = await axios.get('https://api.jakestory.me/movie/auto');
    return data;
  },
});

export const MovieAuto: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const movieStateLoadable = useRecoilValueLoadable(MOVIES_STATE);
  const inputRef = useRef({
    title: '',
    type: '',
    regions: '',
    seatNum: 1,
    afterYMD: '',
    beforeYMD: '',
    afterHHMM: '',
    beforeHHMM: ''
  });

  const sendReservation = useCallback(async () => {
    const { title: name, type: movieType, regions: theaters, seatNum, afterHHMM, afterYMD, beforeHHMM, beforeYMD } = inputRef.current;
    const { data } = await axios.post(
      'https://api.jakestory.me/movie/auto',
      {
        name, movieType, theaters, seatNum, afterHHMM, afterYMD, beforeHHMM, beforeYMD,
      }
    );
    console.log(data);
  }, [inputRef]);

  const deleteReservation = useCallback(async (id: number) => {
    await axios.delete(
      'https://api.jakestory.me/movie/auto/' + id,
    );
  }, [])

  return <Body>
    <div className={css(styles.inputBox)}>
      <div className={css(styles.title_label)}>1. 영화 제목</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.title = e.target.value}
        placeholder={'검색할 영화 제목을 입력해주세요.'}
      />
      <div className={css(styles.title_label)}>2. 영화 타입 (All or IMAX)</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.type = e.target.value}
        placeholder={'검색할 영화 종류를 입력해주세요.'}
      />
      <div className={css(styles.title_label)}>3. 영화관 (강남, 압구정, 왕십리, 용산)</div>
      <input
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.regions = e.target.value}
        placeholder={'강남, 압구정, 왕십리, 용산'}
      />
      <div className={css(styles.title_label)}>4. 좌석 수</div>
      <input
        type={'number'}
        min={1}
        defaultValue={1}
        className={css(styles.movie_input)}
        onChange={e => inputRef.current.seatNum = Number(e.target.value)}
      />
      <div className={css(styles.title_label)}>5. 날짜 범위 (선택)</div>
      <div style={{ display: 'flex', gap: 5, }}>
        <input
          className={css(styles.movie_input)}
          onChange={e => inputRef.current.afterYMD = e.target.value}
          placeholder={'최소 날짜'}
        />
        <input
          className={css(styles.movie_input)}
          onChange={e => inputRef.current.beforeYMD = e.target.value}
          placeholder={'최대 날짜'}
        />
      </div>
      <div className={css(styles.title_label)}>6. 시간 범위 (선택)</div>
      <div style={{ display: 'flex', gap: 5, }}>
        <input
          className={css(styles.movie_input)}
          onChange={e => inputRef.current.afterHHMM = e.target.value}
          placeholder={'시작 시간대'}
        />
        <input
          className={css(styles.movie_input)}
          onChange={e => inputRef.current.beforeHHMM = e.target.value}
          placeholder={'끝 시간대'}
        />
      </div>
      <button
        type={'button'}
        className={css(styles.inputButton)}
        onClick={sendReservation}
      >
        추가
      </button>
    </div>
    <div className={css(styles.output)}>
      <div className={css(styles.times)}>
        {movieStateLoadable.state === 'hasValue' && movieStateLoadable.contents.map((item, index) => (
          <>
            <div className={css(styles.reservationBox)}>
              <div>
                <p className={css(styles.reservationLabel)}>제목</p>
                <div className={css(styles.reservationValue)}>{item.name}</div>
              </div>
              <div>
                <p className={css(styles.reservationLabel)}>타입</p>
                <div className={css(styles.reservationValue)}>{item.movieType}</div>
              </div>
              <div>
                <p className={css(styles.reservationLabel)}>영화관</p>
                <div className={css(styles.reservationValue, styles.reservationLong)}>
                  {item.theaters}
                </div>
              </div>
              <div>
                <p className={css(styles.reservationLabel)}>좌석수</p>
                <div className={css(styles.reservationValue)}>{item.seatNum}</div>
              </div>
              <div>
                <p className={css(styles.reservationLabel)}>날짜범위</p>
                <div className={css(styles.reservationValue, styles.reservationLong)}>
                  {item.afterYMD}~{item.beforeYMD}
                </div>
              </div>
              <div>
                <p className={css(styles.reservationLabel)}>시간범위</p>
                <div className={css(styles.reservationValue, styles.reservationLong)}>
                  {item.afterHHMM}~{item.beforeHHMM}
                </div>
              </div>
              <input
                type={'button'}
                className={css(styles.deleteButton)}
                value={'삭제'}
                onClick={() => deleteReservation(item.id)}
              />
            </div>
            <div className={css(styles.result)}>
              <div>결과: {item.result ?? '미정'}</div>
              <div>예매번호: {item.reservationNo ?? '미정'}</div>
              <div>좌석번호: {item.seatNoList ?? '미정'}</div>
            </div>
          </>
        ))}
      </div>
    </div>
  </Body>;
};

const styles = StyleSheet.create({
  inputBox: {
    flexShrink: 1,
    width: 300,
    height: 'calc(100vh - 100px)',
    borderRadius: 20,
    borderColor: '#000',
    borderWidth: 1,
    // backgroundColor: '#000'
    marginRight: 20,

    padding: '30px 20px',
    position: 'relative',
  },
  output: {
    flexShrink: 0.5,
    width: 700,
    height: 'calc(100vh - 100px)',

    padding: '20px 20px',
    userSelect: 'auto',
  },
  title_label: {
    fontFamily: 'Spoqa',
    fontWeight: 700,

    marginBottom: 8,
  },
  movie_input: {
    width: '100%',
    height: 40,

    borderRadius: 10,
    borderColor: '#000',
    borderWidth: 1,
    padding: 18,

    marginBottom: 16,
  },
  times: {
    paddingBottom: 20,
    width: '100%',
    minHeight: 120,
    borderBottom: '1px solid #E2E4E6',

    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
  },
  inputButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 200,
    height: 50,

    borderRadius: 10,
    backgroundColor: '#006AFF',

    color: '#FFF',
    fontSize: 16,
    fontWeight: 700,

    cursor: 'pointer',
  },
  reservationBox: {
    position: 'relative',
    width: '100%',
    height: 60,
    borderRadius: 10,
    backgroundColor: '#E2E4E6',
    padding: 10,

    display: 'flex',
    alignItems: 'center',
  },
  reservationLabel: {
    marginBottom: 8,
    fontFamily: 'Spoqa',
    fontSize: 10,
    height: 16,
    textAlign: 'center',
  },
  reservationValue: {
    fontFamily: 'Spoqa',
    fontWeight: 700,
    fontSize: 14,

    width: 40,
    height: 20,
    textAlign: 'center',
  },
  reservationLong: {
    width: 140,
    fontSize: 11,
  },
  deleteButton: {
    position: 'absolute',
    right: 10,

    width: 80,
    height: 30,
    borderRadius: 10,
    backgroundColor: '#E92B2B',

    color: '#FFF',
    fontSize: 12,
    fontWeight: 700,

    cursor: 'pointer',
  },
  result: {
    width: '100%',
    height: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: '#EEE',

    display: 'flex',
    alignItems: 'center',
    gap: 10,
  }
});

import React, { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { css, StyleSheet } from 'aphrodite';

import { isLoadingState } from '../../shared/presentation/LoadingFrame';

export const imageUrlState = atom<string>({
  key: 'imageUrlState',
  default: '',
})

export const ImageDetailView: React.FC = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useHistory();
  const url = useRecoilValue(imageUrlState);
  const [imageUrls, setImageUrls] = useState([]);
  const location = useLocation();
  const query = qs.parse(location.search);

  const uploadImages = useCallback(() => {
    const { url } = query;
    if (url == null) {
      history.replace('/image');
    }
    axios.get(`https://api.jakestory.me/image-scraper/detail?url=${url}`)
      .finally(() => setIsLoading(false))
      .then(({ data }) => setImageUrls(data));
  }, [url])

  const onClickSave = useCallback(() => {
    const { url } = query;
    if (url == null) {
      return
    }
    setIsLoading(true)
    axios.post('https://api.jakestory.me/image-scraper/save', { url })
      .finally(() => setIsLoading(false))
  }, [url])

  useEffect(() => {uploadImages()}, [query])

  return (
    <div className={css(styles.wrapper)}>
      {imageUrls.map((url, index) => (
        <img
          key={'image-' + index}
          src={url}
          className={css(styles.imagesBox)}
        />
      ))}
      <div className={css(styles.button)} onClick={onClickSave}>Save</div>
    </div>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagesBox: {
    maxWidth: '100%',
    height: '120vh',
  },
  button: {
    position: 'fixed',
    bottom: 10,
    right: 10,

    width: 40,
    height: 40,
    backgroundColor: '#0094FF',
    borderRadius: 10,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',

    cursor: 'pointer',
  }
});
